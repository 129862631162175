import React, { useState, useEffect } from "react";
import { useAuth } from "../../AuthContext";

const BuyCards = ({ selectedCards, handleSale, cancelSale }) => {
  const [currentPrice, setCurrentPrice] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    setButtonDisabled(!(parseFloat(currentPrice) > 0 && selectedOption !== ""));
  }, [currentPrice, selectedOption]);
  const sellCard = () => {
    const keychain = window.hive_keychain;
    const stringifyCards = selectedCards.map((el) => JSON.stringify(el));
    const payload = {
      contractName: "nftmarket",
      contractAction: "sell",
      contractPayload: {
        symbol: "PIRATESAGA",
        nfts: stringifyCards,
        price: currentPrice,
        priceSymbol: selectedOption,
        fee: 500,
      },
    };

    keychain.requestCustomJson(
      user,
      "ssc-mainnet-hive",
      "Active",
      JSON.stringify(payload),
      "Sell Cards",
      (response) => {
        console.log(response);
      }
    );
  };

  const handlePriceChange = (e) => {
    setCurrentPrice(e.target.value);
  };

  const handleCurrencyChange = (e) => {
    setSelectedOption(e.target.value);
  };
  return (
    <div className="overlay">
      <div className="sell-container">
        <button onClick={cancelSale} className="close-button">
          x
        </button>
        <h2>List on Market</h2>
        <p>A 5% market fee is deducted from the seller.</p>
        <hr />
        <p>You are about to list {selectedCards.length} cards on the market.</p>
        <input type="text" onChange={handlePriceChange} />
        <select
          className="selectOption"
          value={selectedOption}
          onChange={handleCurrencyChange}
        >
          <option value="">Select an option</option>
          <option value="SWAP.HIVE">SWAP.HIVE</option>
          <option value="LOOT">LOOT</option>
        </select>

        <button onClick={sellCard} disabled={buttonDisabled}>
          Sell
        </button>
        {buttonDisabled ? (
          <p>
            Enter the price you want to sell at and select the token you want to
            list your cards in.{" "}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default BuyCards;
