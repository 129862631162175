import React, { useState } from "react";
import "./GameCommands.css";
import CurrentCommand from "./CurrentCommand";

const GameCommands = () => {
  const [currentCommand, setCurrentCommand] = useState(null);
  const currentCommands = [
    "Set Sail",
    "Special Command",
    "Iron Defense",
    "Barrel Man",
    "Fishing Pole",
  ];

  const handleClickCommand = (e) => {
    setCurrentCommand(e);
  };
  return (
    <div className="gamecommandcontainer">
      <div className="gamecommandlist">
        {currentCommands.map((e) => {
          return (
            <div
              onClick={() => handleClickCommand(e)}
              key={e}
              className="gamecommand"
            >
              {e}
            </div>
          );
        })}
      </div>
      {currentCommand ? <CurrentCommand playCommand={currentCommand} /> : ""}
    </div>
  );
};

export default GameCommands;
