import React, { useState, useEffect } from "react";
import Navbar from "../NavBar/NavBar";
import axios from "axios";
import "./Cards.css";
import AllCards from "./AllCards";
import CurrentCard from "./CurrentCard";

const Market = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const [cardSet, setCardsSet] = useState([]);
  const [currentCard, setCurrentCard] = useState(null);
  const [currentCardImagePath, setCurrentCardImagePath] = useState("");

  useEffect(() => {
    const fetchData = async (offset) => {
      const apis = [
        "https://engine.beeswap.tools/contracts",
        "https://api.hive-engine.com/rpc/contracts",
        "https://herpc.dtools.dev/contracts",
        "https://engine.rishipanthee.com/contracts",
      ];
      const randomIndex = Math.floor(Math.random() * apis.length);
      const randomApi = apis[randomIndex];
      const params = {
        contract: "nftmarket",
        table: "PIRATESAGAsellBook",
        limit: 1000,
        offset: offset,
        indexes: [],
        query: {"priceSymbol": "SWAP.HIVE"}
      };
      const j = {
        jsonrpc: "2.0",
        id: 1,
        method: "find",
        params: params,
      };

      try {
        const response = await axios.post(randomApi, j);
        const data = response.data;

        if (data.result.length === 1000) {
          data.result = data.result.concat(await fetchData(offset + 1000));
        }

        setCards(response.data.result);
        setLoading(false);


        const cardCounts = response.data.result.reduce((counts, card) => {
          const cardName = card.grouping.name;
          const rarity = JSON.parse(card.grouping.nft).rarity;
          const key = `${rarity}_${cardName}`;
          counts[key] = (counts[key] || 0) + 1;
          return counts;
        }, {});
        const cardSetArray = Object.entries(cardCounts).map(([key, count]) => {
          const [rarity, name] = key.split("_");
          return { rarity, name, count };
        });
        setCardsSet(cardSetArray);

        return data.result;
      } catch (error) {
        // setError(error);
        setLoading(false);
        return [];
      }
    };
    fetchData(0);
  }, []);

  const clickHandler = (cardName, imagePath) => {
    console.log(cardName);
    setCurrentCard(cardName);
    setCurrentCardImagePath(imagePath);
  };

  const currentCardClose = () => {
    setCurrentCard(null);
  };

  return (
    <div>
      <Navbar />
      Here are your cards.
      <br />
      {loading ? (
        <p>Loading... Please Wait!</p>
      ) : currentCard === null ? (
        <AllCards cardSet={cardSet} clickHandler={clickHandler} />
      ) : (
        <CurrentCard
          allData={cards}
          currentCard={currentCard}
          currentImagePath={currentCardImagePath}
          closeCard={currentCardClose}
        />
      )}
    </div>
  );
};

export default Market;
