import HomeScreen from "./component/HomeScreen/HomeScreen";

import "./App.css";
import { AuthProvider } from "./AuthContext";

function App() {
  if (process.env.NODE_ENV === "production") {
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
      for (let [key, value] of Object.entries(
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__
      )) {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] =
          typeof value === "function" ? () => {} : null;
      }
    }
  }

  return (
    <AuthProvider>
      <HomeScreen />
    </AuthProvider>
  );
}

export default App;
