const CardDetails = {
  Common: [
    "Common Pirate",
    "Cedrick Firebeard",
    "Cornelia Dread",
    "Jonas Rattler",
    "Benno Briggs",
    "Pistol Pete",
    "Randall Savage",
    "Salty Sarah",
    "Silvera Snake-Eyes",
    "Dax Longblade",
    "GF Common Pirate",
    "GF Cedrick Firebeard",
    "GF Cornelia Dread",
    "GF Jonas Rattler",
    "GF Benno Briggs",
    "GF Pistol Pete",
    "GF Randall Savage",
    "GF Salty Sarah",
    "GF Silvera Snake-Eyes",
    "GF Dax Longblade",
  ],
  Rare: [
    "Augusta Batista",
    "Coco Loco",
    "Finn O'Fish",
    "One-Eyed Jack",
    "Vince Sparrow",
    "Charles Vane",
    "Jack Tar",
    "Janie Wu",
    "Merchant's Glory",
    "GF Augusta Batista",
    "GF Coco Loco",
    "GF Finn O'Fish",
    "GF One-Eyed Jack",
    "GF Vince Sparrow",
    "GF Charles Vane",
    "GF Jack Tar",
    "GF Janie Wu",
    "GF Merchant's Glory",
  ],
  Epic: [
    "Catalina Storm",
    "Charlotte Belle",
    "Ching Yi",
    "Quartermaster Pete",
    "Wade Wilds",
    "Wyatt Gold",
    "Bloodied Blade",
    "GF Catalina Storm",
    "GF Charlotte Belle",
    "GF Ching Yi",
    "GF Quartermaster Pete",
    "GF Wade Wilds",
    "GF Wyatt Gold",
    "GF Bloodied Blade",
  ],
  Legendary: [
    "Bill Bones",
    "Cut-Throat Kelly",
    "Shadow's Veil",
    "GF Bill Bones",
    "GF Cut-Throat Kelly",
    "GF Shadow's Veil",
  ],
  // Unique: ["unique_copper_still"],
};

export default CardDetails;
