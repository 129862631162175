import React from "react";
import CardDetails from "./CardDetails/CardDetails";
import GameDetails from "./GameDetails/GameDetails";
import Navbar from "../NavBar/NavBar";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="homepage-container">
      <Navbar />
      <GameDetails />
      {Object.entries(CardDetails).map(([key, value]) => {
        return (
          <div className="category-container" key={key}>
            <h2 className="category-heading">{key}</h2>
            <div className="image-gallery">
              {value.map((e) => {
                const imagePath = `/CardImages/${key}/${e
                  .split(" ")
                  .join("_")}.png`;
                return (
                  <div className="image-item" key={e}>
                    <img src={imagePath} alt={e.split(" ").join("_")} />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HomePage;
