import React from "react";
import "./UserProfile.css";
import { useAuth } from "../../AuthContext";
import { Navigate } from "react-router-dom";

const UserProfile = () => {
  const { user, userData, isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const renderUserData = () => {
    return Object.entries(userData).map(([key, value]) => (
      <div key={key}>
        <p>
          <strong>{key}</strong>: {value}
        </p>
      </div>
    ));
  };

  return (
    <div>
      <p>Welcome {user}. Here is your data...</p>
      {renderUserData()}
    </div>
  );
};

export default UserProfile;
