import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext";

const NftLeaderboardData = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [visibleDetails, setVisibleDetails] = useState([]);
  const { apiUrl } = useAuth();

  useEffect(() => {
    const fetchnftleaderboardData = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}/nft_leaderboard_piratepower/`
        );
        setLeaderboardData(response.data["result"]);
        setVisibleDetails(
          new Array(response.data["result"].length).fill(false)
        );
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchnftleaderboardData();
  }, [apiUrl]);

  const toggleDetails = (index) => {
    setVisibleDetails((prevVisibleDetails) =>
      prevVisibleDetails.map((visible, i) => (i === index ? !visible : visible))
    );
  };

  const leaderboardPayout = {
    1: 0.18,
    2: 0.13,
    3: 0.09,
    // # Rank 4-8 players get 5%
    4: 0.05,
    5: 0.05,
    6: 0.05,
    7: 0.05,
    8: 0.05,
    //  # Rank 9-14 players get 3%
    9: 0.03,
    10: 0.03,
    11: 0.03,
    12: 0.03,
    13: 0.03,
    14: 0.03,
    //  # Rank 15-20 players get 2%
    15: 0.02,
    16: 0.02,
    17: 0.02,
    18: 0.02,
    19: 0.02,
    20: 0.02,
    // # Rank 21-25 players get 1%
    21: 0.01,
    22: 0.01,
    23: 0.01,
    24: 0.01,
    25: 0.01,
  };

  return (
    <div className="leaderboard-div">
      <h3>Leaderboard Rewards: 7500 Loot, 0.5 SWAP.HIVE, 0.5 DRIP</h3>
      <p className="leaderboard-info">
        Leaderboard Calculations: (Total Packs x 250) + ((Loot held ingame +
        hive engine) * 0.1) + (Pirate power of all cards)
      </p>
      {leaderboardData.length > 0
        ? leaderboardData.map((e, index) => (
            <div
              key={e[0]}
              className={`leaderboard-row ${index > 24 ? "last-50" : ""}`}
              onClick={() => toggleDetails(index)}
            >
              <p className="total">
                {index + 1}. {e[0]} : {e[1].total}{" "}
              </p>
              {visibleDetails[index] && (
                <div className="details">
                  <p>Cards pirate power: {e[1].cards}</p>
                  <p>Loot power: {e[1].loot}</p>
                  <p>Packs power: {e[1].packs}</p>
                  <p className="leaderboard-payout-loot">
                    {index <= 24
                      ? `Loot Paid: ${leaderboardPayout[index + 1] * 7500}`
                      : ""}
                  </p>
                </div>
              )}
            </div>
          ))
        : "Loading..."}
    </div>
  );
};

export default NftLeaderboardData;
