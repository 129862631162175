import React, { useState } from "react";
import SellCards from "./SellCards";

const CurrentCard = ({ allData, currentCard, closeCard, currentImagePath }) => {
  const [selectedCards, setSelectedCards] = useState([]);
  const [sellPrompt, setSellPrompt] = useState(false);
  const currentCardData = allData.filter(
    (card) => card.grouping.name === currentCard
  );

  const handleUnselectAll = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    setSelectedCards([]);
  };
  const handleCardId = (cardId) => {
    if (selectedCards.includes(cardId)) {
      setSelectedCards(selectedCards.filter((id) => id !== cardId));
    } else {
      setSelectedCards([...selectedCards, cardId]);
    }
  };

  const handleSale = () => {
    setSellPrompt(true);
  };

  const cancelSale = () => {
    setSellPrompt(false);
  };

  const handleTransfer = () => {
    console.log("Transfer");
  };

  console.log("here are the selected cards ", selectedCards);
  return (
    <div>
      <button onClick={closeCard}>Back</button>
      <button onClick={handleUnselectAll}>Unselect All</button>
      {sellPrompt ? (
        <SellCards
          handleSale={handleSale}
          cancelSale={cancelSale}
          selectedCards={selectedCards}
        />
      ) : (
        ""
      )}
      {selectedCards.length > 0 ? (
        <div>
          <button onClick={handleSale}>
            Buy
          </button>
          <button onClick={handleTransfer} className="transferButton">
            Transfer
          </button>
        </div>
      ) : (
        ""
      )}
      <div className="currentCardContainer">
        <img className="image-item" src={currentImagePath} alt={currentCard} />
        {currentCardData.map((card, index) => (
          <div className="currentCardIds" key={index}>
            <input
              onClick={() => handleCardId(card.nftId)}
              type="checkbox"
              id="myCheckBox"
              name="myCheckbox"
            />
            <label htmlFor="myCheckBox">{card.nftId}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurrentCard;
