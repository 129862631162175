import React from "react";
import "./GameDetails.css";

const GameDetails = () => {
  return (
    <div className="details-container">
      <h1>Pirate Saga: Quest for Treasure </h1>
      <h2>
        <a
          className="join-link"
          href="https://discord.gg/GpaGRkZpY5"
          target="_blank"
          rel="noreferrer"
        >
          Click here to join discord server and start playing!
        </a>
      </h2>
      <h2>Game Concept:</h2>
      <h3>Overview:</h3>
      <p>
        Pirate Saga: Quest for Treasure is an interactive blockchain-based
        pirate adventure game where players become legendary pirates, own and
        upgrade ships, explore the vast seas, and search for hidden treasures.
        The game is centered around ship and pirate NFTs that hold different
        ranks and powers, creating a competitive and immersive gaming
        experience.
      </p>
      <h3>Core Gameplay Mechanics:</h3>
      <h4>Acquire and Upgrade Ships:</h4>
      <p>
        Players start with a base ship and can acquire or trade for more
        valuable ship NFTs with varying attributes, such as different cooldowns,
        cargo space, and special abilities. Different ships provide advantages,
        such as finding more coins and shorter cooldowns.
      </p>
      <h4>Set Sail Command:</h4>
      <p>
        Players use the "!setsail" command to embark on adventures. The outcome
        of the adventure is determined by a random event system.
      </p>
      <ul>
        <li>Base Ship (30 min cooldown):</li>
        <ul>
          <li>
            1% - You find sunken treasure (You gain 1 chest in inventory).
          </li>
          <li>
            5% - You decide to take a shortcut but encounter a storm and your
            ship is in need of repair. (You earn 5 tokens for your bravery but
            your cooldown resets to 30 mins).
          </li>
          <li>
            4% - You discover an island with enough food to restock your ship.
            Ship morale is up. (You gain 50 tokens).
          </li>
          <li>
            5% - Your ship springs a leak and is in need of repair. (You earn 5
            tokens but your cooldown resets to 30 mins).
          </li>
          <li>
            10% - Your food supply is almost gone and morale is low. (You earn 5
            tokens but your cooldown resets to 30 mins)
          </li>
          <li>
            30% - Great weather allows your ship to travel 100 nautical miles
            (You gain 10 tokens).
          </li>
          <li>5% - Find and plunder another ship (You gain 50 tokens).</li>
          <li>40% - Rough seas take their toll. (You only gain 5 tokens).</li>
        </ul>
        <li>Buy Chest Command:</li>
        <ul>
          <li>
            Players can buy Chest for 500 loot coins using !buychest (number of
            chests 1-100)
          </li>
        </ul>
        <li>Open Chest Command:</li>
        <ul>
          <li>
            Players use the "!openchest" command to reveal rewards from their
            chests.
          </li>
          <li>Rewards include:</li>
          <ul>
            <li>
              44% - Chance to find fishes of various rarities (small, medium and
              large) and amount.
            </li>
            <li>32% - Chance to find wood of vaious amounts (1-3).</li>
            <li>17% - Chance to find iron of various amounts (1-2).</li>
            <li>5% - Chance to find 1 copper.</li>
            <li>
              2% - Chance to find a giant stash of coins (500-2000 loot coins).
            </li>
          </ul>
        </ul>
        <li>Barrelman Command - !barrelman</li>
        <ul>
          <li>
            Player's can upgrade their ship to level 1 to use this command-
            !upgradeship
          </li>
          <li>Outcomes are:</li>
          <li>
            Losing 65 loot coins because of negative outcomes to earning 250
            loot coins because of positive outcome.
          </li>
          <li>
            Losing 1 iron because of negative outcome to earning resources like
            wood, iron and chest.
          </li>
        </ul>
        <li>Irondefense Command - !irondefense</li>
        <ul>
          <li>
            Player's can upgrade their ship to level 2 to use this command-
            !upgradeship
          </li>
          <li>Outcomes are:</li>
          <li>
            Losing 35 loot coins because of negative outcomes to earning 450
            loot coins because of positive outcome.
          </li>
          <li>
            Losing 1 iron or 1 copper because of negative outcome to earning
            resources like wood, iron and chest and copper.
          </li>
        </ul>
        <li>
          Quest (!quest)- It takes you on a journey where you can find various
          ourcomes like loot coins, fishes, resources like wood, iron and
          copper. Every now and then you can find some nfts also.
        </li>
      </ul>
      <h4>Pirate Cards and Pirate Power:</h4>
      <p>
        Players collect pirate cards, each with a specific attack, speed and
        pirate power (PP). The more pirate cards you own, the higher your pirate
        power. Higher rarity pirate cards have more powerful abilities and
        contribute more to your pirate power.
      </p>
      <h4>Leaderboard Competition:</h4>
      There is a leaderboard that ranks players based on their accumulated
      pirate power. The total pirate power will be calculated based on:
      <ul>
        <li>Pirate power of each pirate card held by players.</li>
        <li>
          PirateSaga Alpha Packs held by players. Each unopnened pack held by
          user will be equal to 250 pirate power.
        </li>
      </ul>
      Some part of hive, loot and other coins collected from pack sales will be
      distributed to top 50 players based on their accumulated pirate power.
      <h4>Upcoming features and mechanics:</h4>
      <ul>
        <li>
          - Withdraw and Deposit loot coins from your account to hive engine.
        </li>
        <li>- Upgrade ship 3</li>
        <ul>
          <li>
            Players will be able to attack other player ship and loot coins from
            them.
          </li>
          <li>
            Take part in a weekly treasure hunt and loot coins from a big
            treasure (5000 loot coins).
          </li>
        </ul>
      </ul>
      <h2>
        <a
          className="join-link"
          href="https://discord.gg/GpaGRkZpY5"
          target="_blank"
          rel="noreferrer"
        >
          Click here to join discord server and start playing!
        </a>
      </h2>
    </div>
  );
};

export default GameDetails;
