import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext";

const GlobalStats = () => {
  const [globalData, setGlobalData] = useState([]);
  const [sortBy, setSortBy] = useState("nr_coins");
  const [sortOrder, setSortOrder] = useState("desc");
  const { apiUrl } = useAuth();

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/global_stats/`);
        setGlobalData(response.data["global_data"]);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchGlobalData();
  }, [apiUrl]);

  const handleSort = (criteria) => {
    if (sortBy === criteria) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(criteria);
      setSortOrder("desc");
    }
  };

  const sortData = (data) => {
    const sortedData = [...data].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy] - b[sortBy];
      } else {
        return b[sortBy] - a[sortBy];
      }
    });

    return sortedData;
  };

  return (
    <div className="global-stats-container">
      <h2 className="global-stats-heading">
        <span> Packs</span>{" "}
        <button onClick={() => handleSort("num_packs")}>↑↓</button>
        <span> Loot</span>{" "}
        <button onClick={() => handleSort("nr_coins")}>↑↓</button>
        <span>Chest</span>{" "}
        <button onClick={() => handleSort("num_chests")}>↑↓</button>
        <span>Wood</span>{" "}
        <button onClick={() => handleSort("num_wood")}>↑↓</button>
        <span>Iron</span>{" "}
        <button onClick={() => handleSort("num_iron")}>↑↓</button>
        <span>Copper</span>{" "}
        <button onClick={() => handleSort("num_copper")}>↑↓</button>
      </h2>
      <ul className="global-stats-list">
        {sortData(globalData).map((item, index) => (
          <li key={index} className="global-stats-item">
            <span>{index + 1}: </span>
            <span>{item.hive_user_name}: </span>
            <strong>Loot:</strong> <span>{item.nr_coins}, </span>
            <span>&nbsp;&nbsp;</span>
            <strong>Packs:</strong> <span>{item.num_packs}, </span>
            <span>&nbsp;&nbsp;</span>
            <strong>Last played:</strong> <span>{item.last_played},</span>
            <span>&nbsp;&nbsp;</span>
            <strong>Chest:</strong> <span>{item.num_chests},</span>
            <span>&nbsp;&nbsp;</span>
            <strong>Wood:</strong> <span>{item.num_wood},</span>
            <span>&nbsp;&nbsp;</span>
            <strong>Iron:</strong> <span>{item.num_iron},</span>
            <span>&nbsp;&nbsp;</span>
            <strong>Copper:</strong> <span>{item.num_copper}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GlobalStats;
