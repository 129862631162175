import React, { useState, useEffect } from "react";
import Navbar from "../NavBar/NavBar";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import "./shop.css";

const Shop = () => {
  const [packsToBuy, setPacksToBuy] = useState(0);
  const [userBalance, setUserBalance] = useState({});
  const [currency, setCurrentCurrency] = useState("SWAP.HIVE");
  const [packDetails, setPackDetails] = useState([]);
  const [totalAmount, setTotalAmount] = useState(4);
  const [lowBalance, setLowBalance] = useState(false);
  const [confirmTransaction, setAwaitTransaction] = useState(false);
  const [confirmTransactionText, setConfirmTransactionText] = useState(false);
  // const [loadTransactionImage, setLoadTransactionImage] = useState(false);
  const { user, userData, apiUrl, updateData, setUpdateData } = useAuth();
  const userOwnedPacks = userData.Packs;
  const handlePacksChange = (event) => {
    const packAmountInput = event.target.value;
    setPacksToBuy(event.target.value);
    let totalPrice;
    if (currency === "SWAP.HIVE") {
      totalPrice = packAmountInput * packDetails.current_pack_price;
    } else {
      totalPrice = packAmountInput * packDetails[currency];
    }

    if (parseFloat(userBalance[currency]) < totalPrice) {
      setLowBalance(true);
    } else {
      setLowBalance(false);
    }

    setTotalAmount(totalPrice);
  };

  const handleCurrencyChange = (e) => {
    setCurrentCurrency(e.target.value);
    let totalPrice;
    if (e.target.value === "SWAP.HIVE") {
      totalPrice = packsToBuy * packDetails.current_pack_price;
    } else {
      totalPrice = packsToBuy * packDetails[e.target.value];
    }

    if (parseFloat(userBalance[currency]) < totalPrice) {
      setLowBalance(true);
    } else {
      setLowBalance(false);
    }

    setTotalAmount(totalPrice);
  };

  useEffect(() => {
    axios
      .post(`${apiUrl}/packs_shop_details/`)
      .then((response) => {
        setPackDetails(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching pack details.");
      });

    const params_balance = {
      contract: "tokens",
      table: "balances",
      query: {
        account: user,
      },
      limit: 1000,
      offset: 0,
      indexes: [],
    };

    const j = { jsonrpc: "2.0", id: 1, method: "find", params: params_balance };

    // const walletBalance = {
    //   "SWAP.HIVE": 0,
    //   DRIP: 0,
    //   LOOT: 0,
    // };
    axios
      .post("https://api.hive-engine.com/rpc/contracts", j)
      .then((response) => {
        const result = response.data.result;

        const walletBalance = {};
        const symbols = ["SWAP.HIVE", "LOOT", "DRIP"];

        for (const symbol of symbols) {
          const foundElement = result.find(
            (element) => element.symbol === symbol
          );
          walletBalance[symbol] = foundElement ? foundElement.balance : 0;
        }
        setUserBalance(walletBalance);
      });
  }, [user, apiUrl, updateData]);

  const handleSubmitButton = () => {
    const numIsInteger = Number.isInteger(parseInt(packsToBuy));
    if (0 >= packsToBuy || packsToBuy > 100 || !numIsInteger) {
      window.alert("You can only buy 1-100 packs.");
      setPacksToBuy(0);
      return;
    }

    const keychain = window.hive_keychain;
    const payload = {
      contractName: "tokens",
      contractAction: "transfer",
      contractPayload: {
        symbol: currency,
        to: "piratesagapack",
        quantity: `${totalAmount}`,
        memo: `${packDetails.packs_left}, ${packsToBuy}, ${userOwnedPacks}`,
      },
    };

    keychain.requestCustomJson(
      user,
      "ssc-mainnet-hive",
      "Active",
      JSON.stringify(payload),
      `Buy ${packsToBuy} Alpha Packs`,
      (response) => {
        console.log(response);
        if (response.success) {
          // setLoadTransactionImage(true);
          setAwaitTransaction(true);
          const confirmTransaction = (interval) => {
            return new Promise((resolve) => {
              setTimeout(() => {
                axios
                  .post(`${apiUrl}/confirm_transaction_detail/`, {
                    symbol: currency,
                    amount: packsToBuy,
                    amountPaid: totalAmount,
                    userName: user,
                  })
                  .then((response) => {
                    console.log(response);
                    resolve(response.data.result.success);
                  })
                  .catch((error) => {
                    console.error(error);
                    resolve(false); // Resolve false on error to continue the loop
                  });
              }, interval);
            });
          };

          const intervals = [10000, 15000, 20000, 30000, 60000, 120000, 180000];

          const executeTransactions = async () => {
            for (let i = 0; i < intervals.length; i++) {
              const transactionResponse = await confirmTransaction(
                intervals[i]
              );
              if (transactionResponse) {
                setConfirmTransactionText(true);
                setUpdateData(!updateData);
                break;
              } // Break the loop if transactionResponse is true
            }
          };

          executeTransactions();
        }
      }
    );
  };

  const transactionConfirmed = () => {
    setAwaitTransaction(false);
    setConfirmTransactionText(false);
  };

  return (
    <div>
      <Navbar />
      <div className="shop-container">
        {confirmTransaction ? (
          <div className="confirm-transaction-overlay">
            <div className="confirm-transaction">
              <div>
                {confirmTransactionText ? (
                  <button
                    onClick={transactionConfirmed}
                    className="transaction-button"
                  >
                    x
                  </button>
                ) : (
                  ""
                )}
              </div>
              {confirmTransactionText ? (
                <h2>Transaction Confirmed!</h2>
              ) : (
                <p>Confirming Transaction...please wait!</p>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="drop-rate-container">
          <h3 className="drop-rate-heading">Drop Rates</h3>
          <p>Common: 75%</p>
          <p>Rare: 20%</p>
          <p>Epic: 4%</p>
          <p>Legendary: 1%</p>
          <h3 className="drop-rate-heading">Pack Price Distribution:</h3>
          <p>1-5000: 4 swap.hive</p>
          <p>5001-10000: 5 swap.hive</p>
          <p>10001-15000: 6 swap.hive</p>
          <p>15001-20000: 7 swap.hive</p>
          <p>20001-25000: 8 swap.hive</p>
          <h3 className="drop-rate-heading">Total Packs To Be Sold: 25000</h3>
        </div>
        <div className="buy-packs-container">
          <h2>Buy Alpha Packs Here!</h2>
          <h3>Total Packs: 25000</h3>
          <h3>Packs Left: {packDetails.packs_left}</h3>
          <select onChange={handleCurrencyChange}>
            <option value="SWAP.HIVE">SWAP.HIVE</option>
            <option value="DRIP">DRIP</option>
            <option value="LOOT">LOOT</option>
          </select>
          <h3>
            Current Pack Price:{" "}
            {currency === "SWAP.HIVE"
              ? packDetails.current_pack_price
              : packDetails[currency]}{" "}
            {currency}
          </h3>
          <p>Amount to pay: {totalAmount} </p>
          {parseInt(packsToBuy) === 100 ? (
            <p className="drop-rate-heading">
              <strong>Bonus Packs +10</strong>
            </p>
          ) : (
            ""
          )}
          <input
            className="input-text"
            type="number"
            min="1"
            max="100"
            onChange={handlePacksChange}
            value={packsToBuy}
          />
          {lowBalance ? (
            <div>
              Your {currency} balance is low {userBalance[currency]}. Select
              another token to pay with.
            </div>
          ) : (
            <button onClick={handleSubmitButton}>Submit</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Shop;
