import React from "react";
import Login from "../Login/Login";
import UserProfile from "../UserProfile/UserProfile";
import HomePage from "../HomePage/HomePage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PlayGame from "../PlayGame/PlayGamePage/PlayGame";
import Logout from "../Login/Logout";
import Activate from "../Activate/Activate";
import { useAuth } from "../../AuthContext";
import Layout from "../NavBar/Layout";
import Market from "../market/Market";
import Cards from "../Cards/Cards";
import Shop from "../Shop/Shop";
import Open from "../Open/Open";
import Stats from "../Stats/Stats";

const HomeScreen = () => {
  const { isAuthenticated } = useAuth();
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    { path: "user", element: isAuthenticated ? <UserProfile /> : <Login /> },
    { path: "login", element: <Login /> },
    { path: "logout", element: isAuthenticated ? <Logout /> : <Login /> },
    { path: "playgame", element: isAuthenticated ? <PlayGame /> : <Login /> },
    { path: "activate", element: isAuthenticated ? <Activate /> : <Login /> },
    { path: "market", element: isAuthenticated ? <Market /> : <Login /> },
    { path: "cards", element: isAuthenticated ? <Cards /> : <Login /> },
    { path: "shop", element: isAuthenticated ? <Shop /> : <Login /> },
    { path: "open", element: isAuthenticated ? <Open /> : <Login /> },
    { path: "stats", element: isAuthenticated ? <Stats /> : <Login /> },
  ]);
  return (
    <div>
      <RouterProvider router={router}>
        <Layout>
          <HomeScreen />
        </Layout>
      </RouterProvider>
    </div>
  );
};

export default HomeScreen;
