import React from "react";
import { useAuth } from "../../AuthContext";
import { Navigate } from "react-router-dom";

const Logout = () => {
  const { setIsAuthenticated } = useAuth();
  localStorage.removeItem("token");
  setIsAuthenticated(false);
  return <Navigate to="/" />;
};

export default Logout;
