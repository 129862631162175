import React, { useState } from "react";
import { useAuth } from "../../AuthContext";
import "./login.css";
import { Navigate } from "react-router-dom";

const Login = () => {
  const { login, isAuthenticated} = useAuth();
  const [userName, setUsename] = useState("");

  const handleUserName = (e) => {
    setUsename(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    login(userName);
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }
  return (
    <div>
      <div className="container">
        <div className="form-input">
          <label htmlFor="username" className="login-text">
            "Avast, ye scurvy dog! Prepare to sail the digital seas and plunder
            virtual treasures! Ye be needin' yer trusty parrot and a cup o' grog
            to log into the pirate's den."
          </label>
          <input
            type="text"
            onChange={handleUserName}
            placeholder="Enter Your Hive User name"
            id="username"
            name="username"
            className="input-text"
            autoComplete="off"
          />
          <button className="form-button" type="submit" onClick={handleLogin}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
