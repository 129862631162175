import React, { useState } from "react";
import Navbar from "../NavBar/NavBar";
import "./stats.css";
import UserStats from "./UserStats";
import GlobalStats from "./GlobalStats";
import NftLeaderboardData from "./NftLeaderboard";

const Stats = () => {
  const [activeTab, setActiveTab] = useState("userStats");
  const handleTabClick = (tabname) => {
    setActiveTab(tabname);
  };
  return (
    <div>
      <Navbar />
      <div className="stats-container">
        <div onClick={() => handleTabClick("userStats")}>
          <h3
            className={`stats-heading ${
              activeTab === "userStats" ? "active" : ""
            }`}
          >
            User Stats
          </h3>
        </div>
        <div onClick={() => handleTabClick("globalStats")}>
          <h3
            className={`stats-heading ${
              activeTab === "globalStats" ? "active" : ""
            }`}
          >
            Global Stats
          </h3>
        </div>
        <div onClick={() => handleTabClick("nftleaderboard")}>
          <h3
            className={`stats-heading ${
              activeTab === "nftleaderboard" ? "active" : ""
            }`}
          >
            Pirate Power Leaderboard
          </h3>
        </div>
      </div>
      <div className="stats-info">
        {activeTab === "userStats" ? <UserStats /> : ""}
        {activeTab === "globalStats" ? <GlobalStats /> : ""}
        {activeTab === "nftleaderboard" ? <NftLeaderboardData /> : ""}
      </div>
    </div>
  );
};

export default Stats;
