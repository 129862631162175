import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation
import { useAuth } from "../../AuthContext";
import "./NavBar.css";

const Navbar = () => {
  const { isAuthenticated, isActive } = useAuth();
  return (
    <nav className="navbar">
      <ul className="nav-list">
        <li className="nav-item">
          <Link to="/" className="nav-link">
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/shop" className="nav-link">
            Shop
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/open" className="nav-link">
            Open
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/market" className="nav-link">
            Market
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/cards" className="nav-link">
            Cards
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/playgame" className="nav-link">
            Play Game
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/stats" className="nav-link">
            Stats
          </Link>
        </li>
        {isAuthenticated && isActive === false ? (
          <li className="nav-item">
            <Link to="/activate" className="nav-link">
              Activate
            </Link>
          </li>
        ) : (
          ""
        )}
        {isAuthenticated ? (
          <li className="nav-item">
            <Link to="/logout" className="nav-link">
              Logout
            </Link>
          </li>
        ) : (
          <li className="nav-item">
            <Link to="/login" className="nav-link">
              Login
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
