import React from "react";

const AllCards = ({ cardSet, clickHandler }) => {
  return (
    <div>
      {cardSet.map((card, index) => {
        const imagePath = `/CardImages/${card.rarity}/${card.name
          .split(" ")
          .join("_")}.png`;
        return (
          <div className="images-container" key={index}>
            <img
              onClick={() => clickHandler(card.name, imagePath)}
              className="image-item"
              src={imagePath}
              alt={card.name}
            />
            <p className="cards-info">
              {card.name}: {card.count}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default AllCards;
